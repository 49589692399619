import '../styles/globals.css'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import MetamaskUpdater from '../components/MetamaskUpdater'
import NftUpdater from '../components/NftUpdater'
import { Toaster } from 'react-hot-toast'
import { MoralisProvider } from 'react-moralis'
import { IntercomProvider } from 'react-use-intercom'
import { QueryClient, QueryClientProvider } from 'react-query'
 
const queryClient = new QueryClient()

function MyApp({ Component, pageProps }) {
  const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_ID
  return (
    <div className="outer">
      <QueryClientProvider client={queryClient}>
        <MoralisProvider
          appId={process.env.NEXT_PUBLIC_MORALIS_APP_ID}
          serverUrl={process.env.NEXT_PUBLIC_MORALIS_SERVER_URL}
        >
          <IntercomProvider appId={intercomAppId} autoBoot>
            <MetamaskUpdater>
              <NftUpdater>
                <Navbar />
                <Component {...pageProps} />
                <Footer />
                <Toaster />
              </NftUpdater>
            </MetamaskUpdater>
          </IntercomProvider>
        </MoralisProvider>
      </QueryClientProvider>
    </div>
  )
}

export default MyApp
