import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { NftContext } from "./NftContext"
import { fetchNftDataFile } from '../utils/Nfts'

const NftUpdater = (props) => {
  const queryClient = useQueryClient()
  const [nfts, setNfts] = useState({
    version: 0,
    nftData: [],
    displayCategories: ['Equinox'],
  })

  useEffect(() => {
    const handleGetNftInfo = async () => {
      try {
        const data = await fetchNftDataFile(nfts, queryClient)
        setNfts(data)
      } catch (err) {
        console.log(err)
        // do not update data on error
      }
    }

    handleGetNftInfo()
  }, [])

  const reloadNfts = useCallback(async () => {
    try {
      const data = await fetchNftDataFile(nfts, queryClient)
      setNfts(data)
    } catch (err) {
      console.log(err)
      // do not update data on error
    }
  }, [])

  const contextValue = useMemo(() => ({
    nfts,
    reloadNfts
  }), [nfts, reloadNfts])

  return <NftContext.Provider value={contextValue}>{props.children}</NftContext.Provider>
}

export default NftUpdater
