import React from 'react'
import LogoSvg from '/public/logo.svg'
import { Typography } from '@equinox-fund/equinox-lib'
import { AiFillInstagram } from 'react-icons/ai'
import { FaMediumM, FaTelegramPlane, FaTwitter, FaYoutube } from 'react-icons/fa'
import styles from './styles'

const Footer = () => (
  <footer className="container" css={styles}>
    <div className="footer-inner">
      <div className="brand">
        <img src={LogoSvg.src} alt="Equinox" />
        <Typography size="medium" caption className="footer-text">
          A fair and decentralized NFT marketplace. Buy, rent and lend NFTs to access the Equinox
          Ecosystem.
        </Typography>
      </div>
      <nav className="nav">
        <div className="footer-socials">
          <a
            href="https://nox-ecosystem.medium.com/"
            target="_blank"
            rel="noreferrer noopener"
            title="Medium"
          >
            <FaMediumM size={28} />
          </a>
          <a
            href="https://twitter.com/NOX_Ecosystem"
            target="_blank"
            rel="noreferrer noopener"
            title="Twitter"
          >
            <FaTwitter size={28} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCuEtWYEYVqYoC7HUsj1zhfw/"
            target="_blank"
            rel="noreferrer noopener"
            title="Youtube"
          >
            <FaYoutube size={28} />
          </a>
          <a
            href="https://t.me/Equinox_Official_Annoucements"
            target="_blank"
            rel="noreferrer noopener"
            title="Telegram"
          >
            <FaTelegramPlane size={28} />
          </a>
          <a
            href="https://www.instagram.com/equinox_crypto/"
            target="_blank"
            rel="noreferrer noopener"
            title="Instagram"
          >
            <AiFillInstagram size={28} />
          </a>
        </div>
      </nav>
    </div>
  </footer>
)

export default Footer
