import { ethers } from 'ethers'
import { useEffect } from 'react'

export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (err) {
      reject(err)
    }
  })
}

export function downloadURI(uri, name) {
  const matches = uri.match(/^data:.+\/(.+);base64,(.*)$/)
  let ext = matches[1]
  if (ext === 'plain') {
    ext = 'txt'
  }
  const link = document.createElement('a')
  link.download = `${name}.${ext}`
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function stringFloatToBnPrice(numStr) {
  if (!numStr) return ethers.BigNumber.from(0)
  const commaIndex = numStr.indexOf('.')
  if (commaIndex >= 0) {
    let decimals = numStr.length - 1 - commaIndex
    if (decimals > 18) decimals = 18
    let numStrNoComma =
      numStr.slice(0, commaIndex) + numStr.slice(commaIndex + 1, commaIndex + 1 + decimals)
    const regex = new RegExp('^0+(?!$)', 'g')
    numStrNoComma = numStrNoComma.replaceAll(regex, '')
    const rv = ethers.BigNumber.from(numStrNoComma).mul(
      ethers.BigNumber.from(10).pow(18 - decimals)
    )
    return rv
  } else {
    return ethers.BigNumber.from(numStr).mul(ethers.BigNumber.from(10).pow(18))
  }
}

export function bnToId(bn) {
  return (
    '0x' +
    (
      '0000000000000000000000000000000000000000000000000000000000000000' + bn.toHexString().slice(2)
    ).slice(-64)
  )
}

export function normalizeId(nftId) {
  if (!ethers.BigNumber.isBigNumber(nftId)) nftId = ethers.BigNumber.from(nftId)

  return bnToId(nftId)
}

export const useClickOutsideListener = (ref) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        alert('You clicked outside of me!')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
