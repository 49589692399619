import { EquinoxMarketplace } from './EquinoxMarketplace'
import { EquinoxTokens } from './EquinoxTokens'

import { Contract, utils, BigNumber } from 'ethers'

export class EquinoxBlockchainBackend {
  constructor(signer, marketplaceAddress, nftAddress) {
    this._signer = signer
    this._marketplace = new Contract(marketplaceAddress, EquinoxMarketplace.output.abi, signer)
    this._marketplaceAddress = marketplaceAddress
    this._nft = new Contract(nftAddress, EquinoxTokens.output.abi, signer)
    this._nftAddress = nftAddress
  }

  async proposeToken(tokenId) {
    try {
      const tx = await this._nft.proposeToken(tokenId)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async cancelProposal(tokenId) {
    try {
      const tx = await this._nft.cancelProposal(tokenId)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // only for admin
  async createToken(tokenId, name, minterAddress) {
    try {
      const tx = await this._nft.addToken(tokenId, name, minterAddress)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // only for admin
  async rejectToken(tokenId) {
    try {
      const tx = await this._nft.cancelProposal(tokenId)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // only for minter
  async mintToken(tokenId, amount, destinationAddress) {
    try {
      const data = utils.defaultAbiCoder.encode(['uint256'], [0])
      const tx = await this._nft.mintToken(destinationAddress, tokenId, amount, data)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async isAdmin(account) {
    const ADMIN_ROLE = utils.id('ADMIN_ROLE')
    return await this._nft.hasRole(ADMIN_ROLE, account)
  }

  async isProposer(account) {
    const PROPOSER_ROLE = utils.id('TOKEN_PROPOSER_ROLE')
    return await this._nft.hasRole(PROPOSER_ROLE, account)
  }

  async transferToken(tokenId, fromAddress, destinationAddress, amount) {
    try {
      const data = utils.defaultAbiCoder.encode(['uint256'], [0])
      const tx = await this._nft.safeTransferFrom(
        fromAddress,
        destinationAddress,
        tokenId,
        amount,
        data
      )
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async burnToken(tokenId, destinationAddress, amount) {
    try {
      const tx = await this._nft.burn(destinationAddress, tokenId, amount)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async findPutBehindOffer(tokenId, price) {
    return await this._marketplace.findPutBehindOffer(tokenId, price)
  }

  async transferTokenToMarketplace(tokenId, fromAddress, amount, price) {
    const putBehind = await this.findPutBehindOffer(tokenId, price)
    try {
      const data = utils.defaultAbiCoder.encode(['uint256', 'uint256'], [price, putBehind])
      const tx = await this._nft.safeTransferFrom(
        fromAddress,
        this._marketplaceAddress,
        tokenId,
        amount,
        data
      )
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getUserOffers(account) {
    return await this._marketplace.getUserOffers(account)
  }

  async balanceOfBatch(accounts, ids) {
    return await this._nft.balanceOfBatch(accounts, ids)
  }

  async cancelOffer(uid) {
    try {
      const tx = await this._marketplace.cancelOffer(uid)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getBestOffers(id, count = 10) {
    return await this._marketplace.getBestOffers(id, count)
  }

  async getSupply(id) {
    return await this._nft.tokens(id)
  }

  async acceptOffer(uid, amount, price) {
    try {
      const tx = await this._marketplace.acceptOffer(uid, amount, { value: price })
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getRoyaltyFeesDetails(id) {
    const fee = (await this._marketplace.royaltyFees(this._nftAddress, id)).toNumber()
    const feeDenominator = (await this._marketplace.getFeeDenominator()).toNumber()
    const feeReceiver = (
      await this._marketplace.royaltyFeesReceiver(this._nftAddress, id)
    ).toString()
    return { fee: fee / feeDenominator, feeReceiver: feeReceiver }
  }

  async getExtraFeesDetails(id, userAddress) {
    if (!id || !userAddress)
      return {fee: 0, floor: 0}
    const fee = (await this._marketplace.extraFees(this._nftAddress, id, userAddress)).toNumber()
    const floor =
      (await this._marketplace.floorPrices(this._nftAddress, id, userAddress))
        .div(BigNumber.from('1000000000000'))
        .toNumber() / 1000000
    const feeDenominator = (await this._marketplace.getFeeDenominator()).toNumber()

    return { fee: fee / feeDenominator, floor }
  }

  async areGraylisted(ids, userAddress) {
    var promises = []
    for (var id of ids) {
      promises.push(this._nft.isGraylisted(id, userAddress))
    }
    return await Promise.all(promises)
  }

  async setRoyaltyFee(id, fee) {
    try {
      const tx = await this._marketplace.setRoyaltyFee(this._nftAddress, id, fee)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async setRoyaltyFeeReceiver(id, receiver) {
    try {
      const tx = await this._marketplace.setRoyaltyFeeReceiver(this._nftAddress, id, receiver)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getBestOfferBatch(ids) {
    return await this._marketplace.getBestOfferBatch(ids)
  }

  async stopTransfer(id) {
    try {
      const tx = await this._nft.stopTransfer(id)
      await tx.wait()
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
