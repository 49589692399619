import React from 'react'

export const NftContext = React.createContext({
  nfts: {
    version: 0,
    nftData: [],
    displayCategories: ['Equinox'],
  },
  reloadNfts: ()=>{},
});
