import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDetectClickOutside } from 'react-detect-click-outside'

import binanceImg from '../public/assets/chains/binance.png'

import { getShortAddress } from '../utils/Metamask'

const ProfileMenu = ({
  user,
  open,
  onClickAddress,
  onClickOutside,
  children,
}) => {
  const ref = useDetectClickOutside({ onTriggered: onClickOutside })

  const handleClickAddress = (e) => {
    e.preventDefault()
    onClickAddress(e)
  }

  return (
    <div
      className={open ? 'header__item header__item_user active' : 'header__item header__item_user'}
      style={{ display: 'inline' }}
      ref={ref}
    >
      {children}
      <div className="header__body js-header-body" style={{ backgroundColor: '#353535' }}>
        <div className="header__code">
          <div className="header__number">{getShortAddress(user?.address)}</div>
          <button className="header__copy" onClick={handleClickAddress}>
            <svg className="icon icon-copy">
              <use xlinkHref="#icon-copy"></use>
            </svg>
          </button>
        </div>
        <div className="header__wrap">
          <div className="header__line">
            <div className="header__img">
              <img src={binanceImg.src} alt="Etherium" />
            </div>
            <div className="header__details">
              <div className="header__info">BNB Balance</div>
              <div className="header__money">
                {user ? Math.round(user?.balance * 100000) / 100000 : '0'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProfileMenu.defaultProps = {
  user: {},
  open: false,
  onClickAddress: () => {},
  onClickOutside: () => {},
  children: <></>,
}

ProfileMenu.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  onClickAddress: PropTypes.func,
  onClickOutside: PropTypes.func,
  children: PropTypes.node,
}

export default ProfileMenu
