import React from 'react'
import PropTypes from 'prop-types'

const NavLink = ({ text, dark, onClick, className, active }) => {
  const _className = className || 'header__link' + (active ? ' active' : '')
  return (
    <div className={_className} onClick={onClick}>
      {text || ''}
    </div>
  )
}

NavLink.defaultProps = {
  text: '',
  dark: false,
  onClick: () => {},
}

NavLink.propTypes = {
  text: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  onClick: PropTypes.func,
}

export default NavLink
