import { useRouter } from 'next/router'
import React, { useLayoutEffect, useContext, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import Blockies from 'react-blockies'
import NavLink from './NavLink'
import { UserContext } from "./UserContext"
import NextLink from 'next/link'
import { Title, Button, MenuDropdown } from '@equinox-fund/equinox-lib'

const MobileMenu = ({ open, onClickOutside, chainDropdownItems, imgStyle, warningOpen, children }) => {
  const router = useRouter()
  const user = useContext(UserContext)
  const [top, setTop] = useState(0)

  useLayoutEffect(() => {
    setTop(document.querySelector('.js-header').offsetTop)
  }, [warningOpen])

  const handleClick = (chain, index) => {}

  const handleNavigate = (e, path) => {
    e.preventDefault()
    router.push(path)
    onClickOutside()
  }

  const handleClickAccount = (e) => {
    e.preventDefault()
    router.push(`/profile/${user?.address || ''}`)
    onClickOutside()
  }

  const handleItemClick = () => {
    onClickOutside()
  }

  return (
    <div className={open ? 'header__mobile_menu open' : 'header__mobile_menu'} style={{ top: `${top}px`}}>
      {children}

      <nav>
        <div className="my-4">
          <NextLink href="/marketplace" passHref={true}>
            <a title="Discover" onClick={handleItemClick}>
              <Title size="medium" className="nav-link">
                Discover
              </Title>
            </a>
          </NextLink>

          <a href="https://app.equinox.fund" target="_blank" onClick={handleItemClick} rel="noreferrer">
            <Title size="medium" className="nav-link">
              Launchpad
            </Title>
          </a>

          {user?.address && (
            <NextLink href={`/profile/${user?.address || ''}`} passHref>
              <a onClick={handleClickAccount}>
                <Title size="medium" className="nav-link">
                  My collection
                </Title>
              </a>
            </NextLink>
          )}

          {user?.nftProposer && (
            <div>
              <Button variant="contained" className="nav-link inline-block" onClick={(e) => handleNavigate(e, '/upload')}>
                Upload
              </Button>
            </div>
          )}

          {user?.address ? (
            <div className="header__item header__item_user">
              <button className="header__head">
                <div className="header__avatar">
                  <Blockies seed={user.address?.toLowerCase()} style={{ ...imgStyle }} />
                </div>
                <div className="header__wallet">
                  {Math.round(user?.balance * 1000) / 1000}{' '}
                  <span className="header__currency">BNB</span>
                </div>
              </button>
            </div>
          ) : (
            <div className="inline-block">
              <Button
                variant="outlined"
                color="secondary"
                className="nav-link"
                onClick={(e) => handleNavigate(e, '/connect')}
              >
                Connect Wallet
              </Button>
            </div>
          )}

          <div className="block">
            <MenuDropdown
              items={chainDropdownItems}
              defaultItemName="binance"
              className="inline-block"
              iconsLeft
              >
              Select chain...
            </MenuDropdown>
          </div>
        </div>
      </nav>

    </div>
  )
}

export default MobileMenu
