import tw, { css, theme } from 'twin.macro'

const styles = css`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${tw`pb-16`}
  ${tw`mb-0`}

  .footer-inner {
    justify-content: space-between;
    ${tw`py-8`}
    ${tw`text-center`}

    ${tw`md:flex`}
    ${tw`md:text-left`}
  }

  .brand {
    ${tw`text-secondary`}
    ${tw`max-w-sm`}
    ${tw`mx-auto`}
    ${tw`mb-6`}

    ${tw`md:pr-16`}
    ${tw`md:mb-0`}
    ${tw`md:mx-0`}

    img {
      width: 11.8rem;
      ${tw`mb-6`}
      ${tw`mx-auto`}
      ${tw`md:mx-0`}

      @media (min-width: ${theme`screens.md`}) {
        width: 14.75rem;
      }
    }
  }

  .footer-text {
    ${tw`mb-6`}
    ${tw`text-body-sm`}
    ${tw`md:text-caption-md`}
    ${tw`md:mt-6`}
  }

  .footer-socials {
    ${tw`flex`}
    ${tw`space-x-6`}
    ${tw`mb-6`}
    ${tw`md:justify-end`}
    
    a {
      ${tw`text-neutral-silver`}

      &:hover {
        ${tw`text-secondary`}
      }
    }
  }

  .nav {
    ${tw`flex`}
    ${tw`flex-col-reverse`}
    ${tw`items-center`}
    ${tw`flex-shrink-0`}
    ${tw`md:flex-col`}
    ${tw`md:items-end`}
    
    a {
      display: block;
    }
  }

  .nav-links {
    display: flex;
    ${tw`items-center`}
    ${tw`md:mb-16`}
    ${tw`md:-mt-2`}
  }

  .nav-link {
    display: none;
    ${tw`lg:block`}
    ${tw`mx-6`}
 
    &:last-of-type {
      ${tw`ml-6 mr-12`}
    }
  }
`

export default styles
