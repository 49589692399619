import PropTypes from 'prop-types'
import React from 'react'
import logoDark from '../public/assets/logo-dark.png'
import { useRouter } from 'next/router'
import logoLight from '../public/assets/logo-light.png'

import Image from 'next/image'

const HeaderLogo = ({ onClick }) => {
  return (
    <div
      style={{
        height: '100%',
        width: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 11
      }}
      onClick={onClick}
    >
      <Image src={logoLight} alt="Flash Labs NFT" layout="intrinsic" />
    </div>
  )
}

HeaderLogo.defaultProps = {
  onClick: () => {},
}

HeaderLogo.propTypes = {
  onClick: PropTypes.func,
}

export default HeaderLogo
