import { MoralisDidStore } from './Ipfs'
import { Resolver } from '@fl-did-registry/did-nft-resolver'
import { ethers, utils } from 'ethers'
import { EquinoxBlockchainBackend } from '../contracts/EquinoxBlockchainBackend'
import { bnToId, normalizeId } from './Func'
import {
  CHAIN_ID,
  DID_REGISTRY_CONTRACT,
  IPFS_GW,
  MARKETPLACE_CONTRACT,
  NFT_CONTRACT,
} from './Metamask'

const ETH_RPC_URI = process.env.NEXT_PUBLIC_PROVIDER_URL

export function getProvider(chainId = CHAIN_ID) {
  if (ETH_RPC_URI.slice(0, 2) === 'ws') return new ethers.providers.WebSocketProvider(ETH_RPC_URI)

  return new ethers.providers.JsonRpcProvider(ETH_RPC_URI)
}

export async function isNftAdmin(account) {
  if (!account) {
    return false
  }
  const provider = getProvider()
  const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)

  return await backend.isAdmin(account)
}

export async function isNftProposer(account) {
  const provider = getProvider()
  const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  return await backend.isProposer(account)
}

export async function blockNumberToTs(blockNo) {
  const provider = getProvider()
  const block = await provider.getBlock(blockNo)
  return new Date(block.timestamp * 1000)
}

export async function getTokenMeta(nftId, chainId) {
  const merge = (doc, delta) => {
    for (let prop in delta) {
      if (delta[prop] === null) continue
      if (prop in doc && doc[prop] !== null) {
        if (Array.isArray(doc[prop]) && Array.isArray(delta[prop])) {
          doc[prop] = doc[prop].concat(delta[prop])
        } else if (Array.isArray(doc[prop])) {
          doc[prop].push(delta[prop])
        } else if (Array.isArray(delta[prop])) {
          doc[prop] = [doc[prop]].concat(delta[prop])
        } else if (doc[prop] !== delta[prop]) {
          doc[prop] = [doc[prop], delta[prop]]
        }
      } else {
        doc[prop] = delta[prop]
      }
    }
  }

  const provider = getProvider(chainId)
  const registrySettings = { address: DID_REGISTRY_CONTRACT }
  const didStore = new MoralisDidStore(null, IPFS_GW)
  const resolver = new Resolver(provider, didStore, registrySettings)
  const did = `did:nft:${chainId}:${NFT_CONTRACT}:${nftId}`
  const document = await resolver.read(did)
  let rv = {}
  for (let service of document.service) {
    if (service.id.includes('ClaimRepo')) {
      const claim = JSON.parse(await resolver._claimStorage.get(service.serviceEndpoint))
      merge(rv, claim.claimData)
    }
  }
  return { ...rv, did, id: nftId }
}

export function getContractMetaId(chainId) {
  return normalizeId(ethers.BigNumber.from(utils.id('CONTRACT_METADATA')))
}

export function getNftDid(nftId, chainId) {
  nftId = normalizeId(nftId)
  return `did:nft:${chainId}:${NFT_CONTRACT}:${nftId}`
}

export async function getContractMeta(chainId) {
  const nftId = getContractMetaId(chainId)
  return await getTokenMeta(nftId, chainId)
}

export async function getTxSender(txHash, chainId) {
  const provider = getProvider(chainId)
  const receipt = await provider.getTransactionReceipt(txHash)
  return receipt.from
}

export function getMarketplaceAddress(chainId) {
  return MARKETPLACE_CONTRACT
}

export async function fetchNftDataFile(NFTs, queryClient) {
  try {
    const API_ENDPOINT = `${window.location.origin}/api/collection`

    let data = await queryClient.fetchQuery("nft-data-file", () => fetch(API_ENDPOINT).then(res =>
      res.json()
    ))
    if (!data?.version) {
      // do not update data on error
      return data
    }
  
    if (data.version <= NFTs.version) {
      // just update price and stock information
      data = NFTs
    }
    const ids = data.nftData.map((nft) => nft.id)
    const prices = await getPrices(ids)
    const supplies = await getQuantities(ids)
    for (let i = 0; i < data.nftData.length; i++) {
      data.nftData[i].price =
        prices[i].div(ethers.BigNumber.from('1000000000000')).toNumber() / 1000000
      data.nftData[i].bnPrice = prices[i].toString()
      data.nftData[i].totalSupply = supplies[i].supply
      data.nftData[i].marketplaceSupply = supplies[i].marketplaceSupply
    }
    data.categories = data.categoriesData.map((c) => c.name)
    return data
  } catch (err) {
    console.error("Error catched inside fetchNftDataFile()")
    console.error(err)
    throw(err)
  }
}

export async function fetchCoreNftsQuick(names, queryClient) {
  try {
    const API_ENDPOINT = `${window.location.origin}/api/collection`

    let data = await queryClient.fetchQuery("nft-data-file", () => fetch(API_ENDPOINT).then(res =>
      res.json()
    ))

    if (!data?.version) {
      // do not update data on error
      return {}
    }

    let rv = {}
    for (let name of names) {
      rv[name] = getCoreNFT(data.nftData, name)
    }
    return rv
  } catch (err) {
    console.error("Error catched inside fetchCoreNftsQuick()")
    console.error(err)
    throw(err)
  }
}

export async function fetchNftHistory(did) {
  const API_ENDPOINT = `${window.location.origin}/api/history?did=${did}`

  const res = await fetch(API_ENDPOINT)
  let {history} = await res.json()
  return history
}

export async function getNftBalance(id, account) {
  try {
    if (!account || !id) return 0
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    const rv = await backend._nft.balanceOf(account, id)
    return rv.toNumber()
  } catch (err) {
    console.error("Error catched inside getNftBalance()")
    console.error(err)
    throw(err)
  }
}

export async function getPrices(ids) {
  try {
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    return await backend.getBestOfferBatch(ids)
  } catch (err) {
    console.error("Error catched inside getPrices()")
    console.error(err)
    throw(err)
  }
}

export async function getQuantities(ids) {
  try {
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  
    let rv = []
    for (let bi = 0; bi < Math.ceil(ids.length / 10); bi++) {
      const batch = ids.slice(bi * 10, bi * 10 + 10)
      const balanceRet = await backend._nft.getSupplyAndBalanceOfBatch(batch, MARKETPLACE_CONTRACT)
      for (let i = 0; i < batch.length; i++) {
        rv.push({
          supply: balanceRet.supplies[i].toNumber(),
          marketplaceSupply: balanceRet.balances[i].toNumber(),
        })
      }
    }
  
    return rv
  } catch (err) {
    console.error("Error catched inside getQuantities()")
    console.log(err)
    throw (err)
  }
}

export async function getOffers(id, count = 5) {
  try {
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    const ret = await backend.getBestOffers(id, count)
    var rv = []
    const bnZero = ethers.BigNumber.from(0)
    for (var i = 0; i < count; i++) {
      if (ret.prices[i].gt(bnZero) && ret.amounts[i].gt(bnZero)) {
        rv.push({
          owner: ret.owners[i],
          uid: ret.uids[i].toString(),
          price: ret.prices[i].div(ethers.BigNumber.from('1000000000000')).toNumber() / 1000000,
          bnPrice: ret.prices[i].toString(),
          amount: ret.amounts[i].toNumber(),
        })
      }
    }

    return rv
  } catch (err) {
    console.error("Error catched inside getOffers()")
    console.log(err)
    throw(err)
  }
}

export async function getUserOffers(id, account) {
  try {
    if (!id || !account) return []
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    const ret = await backend.getUserOffers(account)
    var rv = []
    for (var i = 0; i < ret.uids.length; i++) {
      if (id === bnToId(ret.ids[i])) {
        rv.push({
          uid: ret.uids[i].toString(),
          amount: ret.amounts[i].toNumber(),
          price: ret.prices[i].div(ethers.BigNumber.from('1000000000000')).toNumber() / 1000000,
          bnPrice: ret.prices[i].toString(),
        })
      }
    }
    rv.sort((o1, o2) => (o1.price - o2.price))
    return rv
  } catch (err) {
    console.error(`Error catched inside getUserOffers(${id}, ${account})`)
    console.error(err)
    throw err
  }
}

export async function getAllUserOffers(account) {
  try {
    if (!account) return []
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    const ret = await backend.getUserOffers(account)
    var rv = {}
    for (var i = 0; i < ret.uids.length; i++) {
      const id = bnToId(ret.ids[i])
      const newEntry = {
        uid: ret.uids[i].toString(),
        amount: ret.amounts[i].toNumber(),
        price: ret.prices[i].div(ethers.BigNumber.from('1000000000000')).toNumber() / 1000000,
        bnPrice: ret.prices[i].toString(),
      }
      if (!rv[id]) rv[id] = [newEntry]
      else rv[id].push(newEntry)
    }
    return rv
  } catch (err) {
    console.error("Error catched inside getAllUserOffers()")
    console.error(err)
    throw err
  }
}

export async function getNFTByID(nfts, id) {
  try {
    if (!id) return {}
    const idBn = ethers.BigNumber.from(id)
    let nft = nfts.filter((n) => ethers.BigNumber.from(n.id).eq(idBn))
    if (nft.length === 0) return {}
    nft = nft[0]
    let offers = await getOffers(id, 5)
    let history = await fetchNftHistory(nft.did)
    return {
      offers,
      history,
      ...nft,
    }
  } catch (err) {
    console.error("Error catched inside getNFTByID()")
    console.error(err)
    throw err
  }
}

export function checkNftFlags(nft, address) {
  if (!address) address = '0x0'
  const awaitApproval = nft.status === 'INITIALIZED'
  const canMint =
    (nft.status === 'TRADEABLE' || nft.status === 'NONTRADEABLE') &&
    nft.minters.filter((m) => m.toLowerCase() === address.toLowerCase()).length > 0
  const isProposedBy =
    awaitApproval && nft.minters.filter((m) => m.toLowerCase() === address.toLowerCase()).length > 0
  const canTransfer = canMint || nft.status === 'TRADEABLE'
  const canBuy = nft.status === 'TRADEABLE' && nft.marketplaceSupply > 0
  const canSell = nft.status === 'TRADEABLE'
  return { awaitApproval, canMint, canTransfer, canBuy, canSell, isProposedBy }
}

// This works well when size of NFT set is relatively small. When it grows up, think about replacing with proper backend solution.
// TODO: add sorting
export function filterNFTs({ nfts, address, search, page, sort, price, priceRange, category }) {
  const pageLimit = 6
  var filteredNfts = []
  try {
    for (const nft of nfts) {
      const description = nft.description ? nft.description : ''
      const name = nft.name ? nft.name : ''
      const totalSupply = nft.totalSupply ? nft.totalSupply : 0
      if (
        (description.search(new RegExp(search, 'i')) >= 0 ||
          name.search(new RegExp(search, 'i')) >= 0) &&
        totalSupply > 0 &&
        nft.status === 'TRADEABLE' &&
        nft.marketplaceSupply > 0 &&
        (category === 'Any' || nft.category.name === category) &&
        priceRange[0] <= nft.price &&
        priceRange[1] >= nft.price
      ) {
        const flags = checkNftFlags(nft, address)
        filteredNfts.push({
          ...nft,
          canBuy: flags.canBuy,
          canSell: flags.canSell,
          minter: flags.canMint,
        })
      }
    }
    const count = filteredNfts.length
    filteredNfts = filteredNfts.slice((page - 1) * pageLimit, page * pageLimit)
    const rv = {
      nfts: {
        docs: filteredNfts,
        totalDocs: count,
        limit: pageLimit,
        totalPages: Math.ceil(count / pageLimit),
        page: page,
        pagingCounter: page * pageLimit - 1,
        hasPrevPage: page === 1 ? false : true,
        hasNextPage: page * pageLimit > count ? false : true,
        prevPage: page === 1 ? null : page - 1,
        nextPage: page * pageLimit > count ? null : page + 1,
      },
    }
    return rv
  } catch (err) {
    throw err
  }
}

export function getRandomImagesByCategory(nfts, category, count) {
  const selected = nfts.nftData.filter((n) => n.category && n.category.name === category)
  const shuffled = selected.sort(() => 0.5 - Math.random())
  const rv = shuffled.slice(0, count).map((n) => n.image)
  return rv
}

export async function getNFTsByUserAddress(nfts, address) {
  try {
    if (!address || !nfts) return null
    var onsale = {}
    const nftAdmin = await isNftAdmin(address)
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    const offersRet = await backend.getUserOffers(address)
    for (var i = 0; i < offersRet.uids.length; i++) {
      var id = bnToId(offersRet.ids[i])
      if (onsale[id]) onsale[id] += offersRet.amounts[i].toNumber()
      else onsale[id] = offersRet.amounts[i].toNumber()
    }
  
    var rv = []
    var nftIds = nfts.map((n) => n.id)
    const balanceRet = await backend.balanceOfBatch(Array(nftIds.length).fill(address), nftIds)
    for (i = 0; i < nftIds.length; i++) {
      const id = nftIds[i]
      const nft = nfts[i]
      const flags = checkNftFlags(nft, address)
      let userNft = nft
      userNft.onsale = onsale[id] ? onsale[id] : 0
      userNft.owned = balanceRet[i].toNumber()
      userNft.minter = flags.canMint
      userNft.canEnable = nftAdmin && flags.awaitApproval
      userNft.canBuy = flags.canBuy
      userNft.canSell = flags.canSell
  
      if (
        userNft.onsale > 0 ||
        userNft.owned > 0 ||
        userNft.canEnable ||
        userNft.minter ||
        flags.isProposedBy
      ) {
        rv.push(userNft)
      }
    }
    nftIds = rv.map((n) => n.id)
    const graylisted = await backend.areGraylisted(nftIds, address)
    for (i = 0; i < rv.length; i++) {
      rv[i].graylisted = graylisted[i]
    }
    return rv    
  } catch (err) {
    console.error("Error catched inside getAllUserOffers()")
    console.error(err)
    throw err
  }
}

export async function getRoyaltyFeesDetails(id) {
  try {
    if (!id) return {fee: 0, feeReceiver: null}
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    return await backend.getRoyaltyFeesDetails(id)
  } catch (err) {
    console.error("Error catched inside getRoyaltyFeesDetails()")
    console.error(err)
    throw err
  }
}

export async function getExtraFeesDetails(id, address) {
  try {
    const provider = getProvider()
    const backend = new EquinoxBlockchainBackend(provider, MARKETPLACE_CONTRACT, NFT_CONTRACT)
    return await backend.getExtraFeesDetails(id, address)
  } catch (err) {
    console.error("Error catched inside getExtraFeesDetails()")
    console.error(err)
    throw err
  }
}

export function getProposerCategories(categoriesData, address) {
  if (!address) return { default: '', allowed: [] }

  for (var category of categoriesData) {
    const found = category.Minter.find((m) => m.address.toLowerCase() === address.toLowerCase())
    if (found) return { default: category.name }
  }
  return { default: 'DEFAULT_CATEGORY' }
}

export function getCoreNFT(nfts, name) {
  const core = nfts.filter((n) => n.features && n.features.includes('CORENFT'))
  const selected = core.find((n) => n.name === name)
  return selected || {}
}
