import React from 'react'
import PropTypes from 'prop-types'

const WarningOverlay = ({ open, text, onClose }) => {
  const handleClickClose = (e) => {
    e.preventDefault()
    onClose(e)
  }

  if (!open) return <></>
  return (
    <div
      style={{
        minHeight: 60,
        backgroundColor: '#6560bd',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '2vw',
        paddingLeft: '2vw',
        textAlign: 'center',
      }}
    >
      <p style={{ fontSize: '16px', textAlign: 'center' }}>{text}</p>
    </div>
  )
}

WarningOverlay.defaultProps = {
  open: false,
  text: 'The marketplace is only compatible with Binance Smart Chain for now, please switch your network',
  onClose: () => {},
}

WarningOverlay.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  onClose: PropTypes.func,
}

export default WarningOverlay
