import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import Blockies from 'react-blockies'
import NextLink from 'next/link'
import HeaderLogo from './HeaderLogo'
import ProfileMenu from './ProfileMenu'
import MobileMenu from './MobileMenu'
import WarningOverlay from './WarningOverlay'
import { UserContext } from "./UserContext"
import {
  Button,
  MenuDropdown,
  MenuButton,
  Ethereum as EthereumIcon,
  BinanceSmartChain as BinanceSmartChainIcon,
  Polygon as PolygonIcon,
} from '@equinox-fund/equinox-lib'

import { CHAIN_ID } from '../utils/Metamask'

const imgStyle = {
  objectFit: 'cover',
  minWidth: '100%',
  minHeight: '100%',
}

const Navbar = ({ dark }) => {
  const router = useRouter()

  const user = useContext(UserContext)
  const [accountOpen, setAccountOpen] = useState(false)
  const [chainsOpen, setChainsOpen] = useState(false)
  const [warningOpen, setWarningOpen] = useState(true)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleAccountOpen = () => {
    setAccountOpen(!accountOpen)
  }

  const handleCloseAccountPopup = () => {
    setAccountOpen(false)
  }

  const toggleChainsOpen = () => {
    setChainsOpen(!chainsOpen)
  }

  const handleCloseChainsPopup = () => {
    setChainsOpen(false)
  }

  const handleClickAccount = (e) => {
    e.preventDefault()
    router.push(`/profile/${user?.address || ''}`)
    setAccountOpen(false)
  }

  const handleClickItems = (e) => {
    e.preventDefault()
    router.push(`/profile/${user?.address || ''}`)
    setAccountOpen(false)
  }

  const handleNavigate = (e, path) => {
    e.preventDefault()
    setMobileMenuOpen(false)
    router.push(path)
  }

  const getIsRoute = (route) => {
    return '/' + router.pathname.split('/')[1] === route
  }

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(user?.address)
  }

  const handleCloseWarning = () => {
    setWarningOpen(false)
  }

  const chainDropdownItems = [
    {
      name: 'binance',
      label: 'BNB Smart Chain',
      icon: <BinanceSmartChainIcon className="w-4" />,
      onClick: () => false,
    },
    {
      name: 'polygon',
      label: 'Polygon',
      icon: <PolygonIcon className="w-4" />,
      onClick: () => false,
      disabled: true,
    },
    {
      name: 'ethereum',
      label: 'Ethereum',
      icon: <EthereumIcon className="w-4" />,
      onClick: () => false,
      disabled: true,
    },
  ]

  const warningOverlayOpen = warningOpen && user?.chainId !== parseInt(CHAIN_ID) && user?.chainId !== -1

  return (
    <>
      <WarningOverlay
        open={warningOverlayOpen}
        onClose={handleCloseWarning}
      />
      <header className="header js-header" data-id="#header">
        <div className="header__center center">
          <HeaderLogo dark={dark} onClick={(e) => handleNavigate(e, '/')} />
          <div className="header__mobile__flex"></div>

          <div className="header__wrapper js-header-wrapper">
            <nav className="header__nav">
              <NextLink href="/marketplace" passHref>
                <a>
                  <Button
                    variant="text"
                    color="secondary"
                    className="px-1"
                    onClick={(e) => handleNavigate(e, '/marketplace')}
                  >
                    Discover
                  </Button>
                </a>
              </NextLink>

              <Button variant="text" color="secondary">
                <a target="_blank" rel="noreferrer" href="https://app.equinox.fund">
                  Launchpad
                </a>
              </Button>
              {user?.address && (
                <NextLink href={`/profile/${user?.address || ''}`} passHref>
                  <a>
                    <Button variant="text" color="secondary" onClick={handleClickAccount}>
                      My collection
                    </Button>
                  </a>
                </NextLink>
              )}
            </nav>
            <div className="flex items-center">
              {user?.nftProposer && (
                <Button
                  variant="contained"
                  className="header__upload"
                  onClick={(e) => handleNavigate(e, '/upload')}
                >
                  Upload
                </Button>
              )}
              {user?.address ? (
                <ProfileMenu
                  user={user}
                  open={accountOpen}
                  onClickAddress={handleCopyAddress}
                  onClickOutside={handleCloseAccountPopup}
                >
                  <button className="header__head" onClick={toggleAccountOpen}>
                    <div className="header__avatar">
                      <Blockies seed={user.address?.toLowerCase()} style={{ ...imgStyle }} />
                    </div>
                    <div className="header__wallet">
                      {Math.round(user?.balance * 1000) / 1000}{' '}
                      <span className="header__currency">BNB</span>
                    </div>
                  </button>
                </ProfileMenu>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="header__upload"
                  onClick={(e) => handleNavigate(e, '/connect')}
                >
                  Connect Wallet
                </Button>
              )}
              <MenuDropdown
                items={chainDropdownItems}
                defaultItemName="binance"
                className="w-54"
                iconsLeft
              >
                Select chain...
              </MenuDropdown>
            </div>
          </div>

          <div className="lg:hidden z-20">
            <MenuButton open={mobileMenuOpen} onClick={(o) => setMobileMenuOpen(o)} />
          </div>
          <MobileMenu
            open={mobileMenuOpen}
            onClickOutside={() => setMobileMenuOpen(false)}
            chainDropdownItems={chainDropdownItems}
            imgStyle={imgStyle}
            warningOpen={warningOverlayOpen}
          />
        </div>
      </header>
    </>
  )
}

Navbar.defaultProps = {
  dark: false,
}

Navbar.propTypes = {
  dark: PropTypes.bool,
}

export default Navbar
