import { DIDDocumentFull } from '@fl-did-registry/did-document'
import { DidStore } from '@fl-did-registry/did-ipfs-store'
import { Operator, Resolver } from '@fl-did-registry/did-nft-resolver'
import { ethers, utils } from 'ethers'
import { JWT } from '@fl-did-registry/jwt'
import { setHeader, getHeader } from './Api'
import { EquinoxBlockchainBackend } from '../contracts/EquinoxBlockchainBackend'
import { stringFloatToBnPrice, bnToId, normalizeId } from './Func'
import { MoralisDidStore } from './Ipfs'

export const EXTERNAL_URL_BASE = `https://marketplace.equinox.fund/item/`
export const IPFS_GW = process.env.NEXT_PUBLIC_IPFS_GW
export const NFT_CONTRACT = process.env.NEXT_PUBLIC_NFT_CONTRACT
export const MARKETPLACE_CONTRACT = process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT
export const DID_REGISTRY_CONTRACT = process.env.NEXT_PUBLIC_DID_REGISTRY_CONTRACT
export const CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID

export function getShortAddress(address, showlen = 7) {
  if (!address) return 'Undefined'
  return address.slice(0, showlen + 2) + '...' + address.slice(42 - showlen, 42)
}

export async function isMetamaskConnected() {
  if (!window.ethereum) return false
  const address = (await window.ethereum.request({ method: 'eth_accounts' }))[0]
  if (!address) return false
  return window.ethereum.isConnected()
}

export async function getMetamaskAccount() {
  if (!window.ethereum) throw new Error('Not connected to metamask')
  const address = (await window.ethereum.request({ method: 'eth_accounts' }))[0]
  if (!address) throw new Error('Address not found')
  const balance =
    Number(
      await window.ethereum.request({ method: 'eth_getBalance', params: [address, 'latest'] })
    ) /
    10 ** 18
  return { address: ethers.utils.getAddress(address), balance }
}

export async function connectMetamask() {
  try {
    if (!window.ethereum) throw new Error('Metamask is not installed')
    return await window.ethereum.request({ method: 'eth_requestAccounts' })
  } catch (err) {
    throw err
  }
}

export async function createClaim(data) {
  try {
    const address = (await window.ethereum.request({ method: 'eth_accounts' }))[0]
    if (!address) throw new Error('Address not found')
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = web3Provider.getSigner()
    if (!signer) throw new Error('Signer not found')
    const did = 'did:ethr:' + address
    const jwtOptions = { issuer: did, subject: did }
    const claim = {
      subject: did,
      issuer: did,
      claimData: data,
    }
    return JWT.sign(signer, claim, { ...jwtOptions, algorithm: 'ES256' })
  } catch (err) {
    throw err
  }
}

export async function requestLogin() {
  try {
    const data = {
      login: new Date().toString(),
    }
    const loginToken = await createClaim(data)
    setHeader('Authorization', loginToken)
  } catch (err) {
    throw err
  }
}

export async function verifyLogin() {
  try {
    const vc = getHeader('Authorization')
    if (!vc) {
      throw new Error('User is not logged in')
    }
    const userAddress = (await window.ethereum.request({ method: 'eth_accounts' }))[0]

    const { issuer, claimData } = JWT.decode(vc)
    const [, , address] = issuer.split(':')
    if (address !== userAddress) {
      throw new Error('Token is linked to wrong account')
    }
    const checksumAddress = ethers.utils.getAddress(address)

    await JWT.verify(vc, checksumAddress)
    if (!claimData.login) {
      throw new Error('Login date does not exist')
    }
    const loggedIn = new Date(claimData.login)
    const now = new Date()
    if (now - loggedIn > 1000 * 60 * 58) {
      throw new Error('Token expired')
    }
    return claimData
  } catch (err) {
    throw err
  }
}

export async function ensureLogin(prevErr) {
  try {
    await verifyLogin()
    return true
  } catch (verifyErr) {
    if (prevErr) throw prevErr
    try {
      await requestLogin()
      return ensureLogin(verifyErr)
    } catch (loginErr) {
      throw loginErr
    }
  }
}

export async function getImageFromIpfs(cid) {
  const url = IPFS_GW + cid

  const res = await fetch(url)
  let data = await res.text()
  return data
}

/**
 * Creates NFT.
 */
export async function createNft(
  { name, description, image, categories, features, moralisSaveFile },
  setStateCallback = () => {}
) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const didStore = new MoralisDidStore(moralisSaveFile, IPFS_GW)

  const registrySettings = { address: DID_REGISTRY_CONTRACT }

  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  const operator = new Operator(signer, didStore, registrySettings)

  setStateCallback('Step 1 of 4: authenticating (please sign all transactions)...')
  await didStore.init()

  try {
    var cid = await didStore.save(image)
    var rawCid = await didStore.saveEncoded(image)
  } catch (e) {
    console.error(e)
    throw new Error('IPFS Failed to upload the image')
  }

  // create and upload metadata
  const imagePath = 'ipfs://' + cid
  var descriptor = {
    description,
    name,
    image: imagePath,
    publicImage: IPFS_GW + rawCid,
    categories,
    features,
  }
  const nftId = ethers.BigNumber.from(
    utils.keccak256(utils.toUtf8Bytes(JSON.stringify(descriptor)))
  )
  const nftIdStr = bnToId(nftId)
  descriptor = {
    id: nftIdStr,
    external_url: EXTERNAL_URL_BASE + nftIdStr,
    ...descriptor,
  }

  //create metadata
  const chainIdStr = '0x' + parseInt(CHAIN_ID).toString(16)
  const did = `did:nft:${chainIdStr}:${NFT_CONTRACT}:` + nftIdStr
  const document = new DIDDocumentFull(did, operator)
  await document.create()
  setStateCallback('Step 2 of 4: signing metadata (please sign all transactions)...')
  const claim = await operator.createPublicClaim(descriptor, did)
  const x = await operator.verifyPublicClaim(claim, descriptor)
  setStateCallback('Step 3 of 4: reserving nft ID (please sign all transactions)...')
  await backend.proposeToken(nftId)
  setStateCallback('Step 4 of 4: publishing metadata (please sign all transactions)...')
  try {
    await operator.publishPublicClaim(claim, descriptor)
  } catch (e) {
    console.error(e)
    throw new Error('publishing metadata failed')
  }

  return nftIdStr
}

export function getProposerNft() {
  return normalizeId(ethers.BigNumber.from(utils.id('CONTRACT_METADATA')))
}

export async function createTokenProposer(
  { address, category, moralisSaveFile },
  setStateCallback = () => {}
) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const didStore = new MoralisDidStore(moralisSaveFile, IPFS_GW)
  const registrySettings = { address: DID_REGISTRY_CONTRACT }

  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  const operator = new Operator(signer, didStore, registrySettings)

  setStateCallback('Step 1 of 4: authenticating (please sign all transactions)...')
  await didStore.init()
  const nftIdStr = getProposerNft()

  // setStateCallback("Step 1 of 4: minting proposer tokens (please sign all transactions)...")
  // await backend.mintToken(nftId, 1, address)

  var descriptor = {
    id: nftIdStr,
    proposers: [
      {
        address,
        default_category: category,
        allowed_categories: [],
      },
    ],
  }
  const chainIdStr = '0x' + parseInt(CHAIN_ID).toString(16)
  const did = `did:nft:${chainIdStr}:${NFT_CONTRACT}:` + nftIdStr
  const document = new DIDDocumentFull(did, operator)
  await document.create()
  setStateCallback('Step 2 of 4: signing metadata (please sign all transactions)...')
  const claim = await operator.createPublicClaim(descriptor, did)
  await operator.verifyPublicClaim(claim, descriptor)
  setStateCallback('Step 3 of 4: publishing metadata (please sign all transactions)...')
  await operator.publishPublicClaim(claim, descriptor)
  setStateCallback('Step 4 of 4: granting proposer rights (please sign all transactions)...')
  await backend._nft.grantRole(utils.id('TOKEN_PROPOSER_ROLE'), address)
}

export async function enableNft({ nftId, name, minterAccount }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.createToken(nftId, name, minterAccount)
}

export async function rejectNft({ nftId }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.cancelProposal(nftId)
}

export async function mintNft({ nftId, amount }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const ownAddress = await signer.getAddress()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.mintToken(nftId, amount, ownAddress)
  //await backend.transferTokenToMarketplace(nftId, ownAddress, amount, priceBn)
}

export async function acceptNftOffer({ uid, amount, bnPrice }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.acceptOffer(uid, amount, bnPrice)
}

export async function putOnSale({ nftId, amount, price }) {
  const priceBn = stringFloatToBnPrice(price)
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const ownAddress = await signer.getAddress()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.transferTokenToMarketplace(nftId, ownAddress, amount, priceBn)
}

export async function transferTo({ nftId, destination }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  const ownAddress = await signer.getAddress()
  await backend.transferToken(nftId, ownAddress, destination, 1)
}

export async function burnToken({ nftId, destination, amount }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.burnToken(nftId, destination, amount)
}

export async function setRoyaltyFee({ nftId, fee }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)

  const feeDenominator = (await backend._marketplace.getFeeDenominator()).toNumber()
  const feeBn = ethers.BigNumber.from(Math.round(feeDenominator * fee))

  await backend.setRoyaltyFee(nftId, feeBn)
}

export async function setRoyaltyFeeReceiver({ nftId, receiver }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.setRoyaltyFeeReceiver(nftId, receiver)
}

export async function cancelOffer({ uid }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.cancelOffer(uid)
}

export async function setNontransferable({ nftId }) {
  const web3Provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = web3Provider.getSigner()
  const backend = new EquinoxBlockchainBackend(signer, MARKETPLACE_CONTRACT, NFT_CONTRACT)
  await backend.stopTransfer(nftId)
}
