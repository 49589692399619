import Axios from 'axios'

const DEV_URL = 'http://localhost:8180'
const PROD_URL = ''
const BNB_BINANCE_PRICE_API = 'https://api.binance.com/api/v3/avgPrice?symbol=BNBUSDC'

const api = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? DEV_URL : PROD_URL,
  headers: { Authorization: '' },
})

export function setHeader(field, value) {
  api.defaults.headers[field] = value
}

export function getHeader(field) {
  return api.defaults.headers[field]
}

export async function getBnbPrice() {
  const res = await fetch(BNB_BINANCE_PRICE_API)
  let data = await res.json()
  return parseFloat(data.price)
}

export default api
