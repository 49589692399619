import React, { useEffect, useCallback, useState, useMemo } from 'react'

import { getMetamaskAccount, isMetamaskConnected, CHAIN_ID } from '../utils/Metamask'
import { isNftAdmin, isNftProposer } from '../utils/Nfts'
import { UserContext } from './UserContext'

const AccountUpdater = (props) => {
  const [user, setUser] = useState({
    address: null,
    balance: null,
    nftAdmin: false,
    nftProposer: false,
    chainId: -1,
  })

  useEffect(() => {
    const checkConnection = async () => {
      const isConnected = await isMetamaskConnected()

      if (!isConnected) {
        if (user.address !== null)
          setUser({
            address: null,
            balance: null,
            nftAdmin: false,
            nftProposer: false,
            chainId: -1,
          })
        return
      }

      const { address, balance } = await getMetamaskAccount()
      const chainId = parseInt(await window.ethereum.request({ method: 'eth_chainId' }))
      if (chainId !== parseInt(CHAIN_ID)) {
        setUser({
          address: null,
          balance: null,
          nftAdmin: false,
          nftProposer: false,
          chainId: CHAIN_ID,
        })
        return
      }

      const _user = { address, balance, nftAdmin: false, nftProposer: false, chainId }
      setUser({
        ..._user,
      })

      const nftAdmin = await isNftAdmin(address)
      const nftProposer = await isNftProposer(address)

      setUser({
        ..._user,
        nftAdmin,
        nftProposer,
      })
    }

    checkConnection()
  }, [])

  useEffect(() => {
    //if (!user || !user?.address) return

    const handleChainChanged = () => {
      window.location.reload()
    }

    const handleAccountsChanged = async (accountsChanged) => {
      if (accountsChanged.length === 0) {
        return setUser({
          address: null,
          balance: null,
          nftAdmin: false,
          nftProposer: false,
          chainId: -1,
        })
      }

      const newAccount = accountsChanged[0]
      const chainId = parseInt(await window.ethereum.request({ method: 'eth_chainId' }))
      if (chainId !== parseInt(CHAIN_ID)) {
        return setUser({
          address: null,
          balance: null,
          nftAdmin: false,
          nftProposer: false,
          chainId: -1,
        })
      }

      const balance =
        Number(
          await window.ethereum.request({
            method: 'eth_getBalance',
            params: [newAccount, 'latest'],
          })
        ) /
        10 ** 18
      const _user = {
        ...user,
        nftAdmin: false,
        balance,
        nftProposer: false,
        address: newAccount,
        chainId,
      }

      setUser({
        ..._user,
      })

      const nftAdmin = await isNftAdmin(newAccount)
      const nftProposer = await isNftProposer(newAccount)

      setUser({
        ..._user,
        nftAdmin,
        nftProposer,
      })
    }

    if (window.ethereum) {
      // Subscribe to accounts change
      window.ethereum.on('accountsChanged', handleAccountsChanged)
      window.ethereum.on('connect', handleAccountsChanged)
      window.ethereum.on('disconnect', handleAccountsChanged)
      // Subscribe to network changed
      window.ethereum.on('chainChanged', handleChainChanged)
    }

    return () => {
      if (window.ethereum) {
        ethereum.removeListener('accountsChanged', handleAccountsChanged)
        ethereum.removeListener('connect', handleAccountsChanged)
        ethereum.removeListener('disconnect', handleAccountsChanged)
        ethereum.removeListener('chainChanged', handleChainChanged)
      }
    }
  }, [user])

  const contextValue = useMemo(() => user, [user])

  return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>
}

export default AccountUpdater
