import { IDidStore } from '@fl-did-registry/did-store-interface'
import { addExceptionMechanism } from '@sentry/utils'
import MoralisType, { Moralis } from 'moralis'
import { ValidFileInput, MoralisFileSaveOptions } from 'react-moralis'

export class MoralisDidStore implements IDidStore {
  private _moralisSave: (
    name: string,
    file: ValidFileInput,
    {
      type,
      metadata,
      tags,
      saveIPFS,
      throwOnError,
      onComplete,
      onError,
      onSuccess,
    }?: MoralisFileSaveOptions
  ) => Promise<MoralisType.File | undefined>
  private _readGw: string
  /**
   * @param uri {string} - IPFS API server uri
   */
  constructor(
    moralisSave:
      | undefined
      | ((
          name: string,
          file: ValidFileInput,
          {
            type,
            metadata,
            tags,
            saveIPFS,
            throwOnError,
            onComplete,
            onError,
            onSuccess,
          }?: MoralisFileSaveOptions
        ) => Promise<MoralisType.File | undefined>),
    moralisReadUri: string
  ) {
    this._moralisSave = moralisSave
    this._readGw = moralisReadUri
  }

  async init() {
    await Moralis.authenticate()
  }

  async save(claim: string): Promise<string> {
    if (!this._moralisSave) throw Error('save not provided')
    const handle = await this._moralisSave('nftFile', { base64: btoa(claim) }, { saveIPFS: true })
    // @ts-ignore
    const hash = handle._hash
    return hash
  }

  async saveEncoded(b64claim: string): Promise<string> {
    if (!this._moralisSave)
      throw Error("save not provided")
    const handle = await this._moralisSave("dummyName", {base64: b64claim}, { saveIPFS: true })
    // @ts-ignore
    const hash = handle._hash
    return hash
  }

  async get(cid: string): Promise<string> {
    if (!this._readGw) throw Error('read uri not provided')

    const url = this._readGw + cid

    const res = await fetch(url)
    let data = await res.text()
    return data
  }

  async delete(uri: string): Promise<boolean> {
    // Not implementable with moralis
    return false
  }
}
